// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import './includes/sidebars.scss';


.hidden {
    visibility: hidden;
}

.remove-item {
    border: 1px solid #dee2e6 !important;
    background: none;
    border-radius: 50px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
}

.remove-item:hover {
    background-color: #a5a8aa !important;
}
